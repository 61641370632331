import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

interface AccommodationPageProps {
  data: {
    file: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

const AccommodationPage = (props: AccommodationPageProps) => (
  <div>
    <Img
      fluid={props.data.file.childImageSharp.fluid}
      alt=""
      style={{
        width: "100%",
        maxHeight: 440,
        backgroundColor: "#D3D9DD",
      }}
    />
    <h3
      style={{
        fontFamily: "Raleway",
        textAlign: "center",
        fontSize: "16pt",
        fontWeight: 200,
        padding: 20,
        maxWidth: "900px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "20px",
      }}
    >
      Accommodation is available onsite at Coulsdon Manor. If you would like to
      reserve a room, please call the venue directly on{" "}
      <span style={{ fontWeight: 800 }}>020 8668 0414</span> and quote the group
      reference number <span style={{ fontWeight: 800 }}>63736</span> when
      making your booking. Room prices are as follows:
    </h3>
    <table className="pricing-table">
      <tr>
        <td>Standard double</td>
        <td>£85</td>
      </tr>
      <tr>
        <td>Standard twin</td>
        <td>£85</td>
      </tr>
      <tr>
        <td>Luxury double</td>
        <td>£110</td>
      </tr>
      <tr>
        <td>Interconnected</td>
        <td>£170</td>
      </tr>
      <tr>
        <td>Additional pull-out bed (12yr and under)</td>
        <td>£20</td>
      </tr>
    </table>
    <h3
      style={{
        fontFamily: "Raleway",
        textAlign: "center",
        fontSize: "16pt",
        fontWeight: 200,
        padding: 20,
        maxWidth: "900px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      All prices include breakfast the following morning.
    </h3>
  </div>
);

export const pageQuery = graphql`
  query AccommodationQuery {
    file: file(relativePath: { eq: "coulsdon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default AccommodationPage;
